import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { PostGrid } from '../components/Post/Grid/';

import style from '../styles/pages/posts.scss';

import { graphql } from 'gatsby';

interface IFeaturedProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * featured page
 *
 * Loads all featured posts from featured-posts.json.
 *
 */
const postsPage = ({ location, data, pageContext }: IFeaturedProps) => {
  const posts = data.allGhostPost.edges;

  return (
    <>
      <MetaData
        location={location}
        data={{}}
        title="Personal Finance Articles from Think Save Retire"
        description="Read the articles on Think Save Retire that have helped the most people achieve financial independence and goals such as retiring by 40."
      />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.totalCount}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p>
                  Page {pageContext.humanPageNumber}
                  <span>&#183;</span>Posts {18 * pageContext.humanPageNumber - 17} of{' '}
                  {data.allGhostPost.totalCount}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.posts}>
          <div className="container">
            <PostGrid posts={posts} />
            <Pagination className={style.pagination} pageContext={pageContext} />
          </div>
        </div>
        <SignUpBanner />
      </Layout>
    </>
  );
};

export default postsPage;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          slug
          feature_image
          title
          published_at
          primary_author {
            name
            profile_image
          }
          tags {
            name
            slug
          }
          html
        }
      }
    }
  }
`;
